// Colors
$primary: #007bc7;
$info: lightSeaGreen;
$danger: crimson;
$warning: darkorange;
$success: yellowgreen;
$light: hsl(220, 20%, 90%);
$dark: hsl(220, 20%, 20%);

$lightest: hsl(220, 20%, 97%);
$darkest: hsl(220, 20%, 15%);
$border: hsl(220, 20%, 94%);

:root {
	--lightest: #{$lightest};
	--darkest: #{$darkest};
	--border: #{$border};
	--primaryOutline1: #{$primary}11;
	--primaryOutline2: #{$primary}22;
	--Black: #000;
	--White: #fafafa;
	--Grey: #708090;
	--Red: #dc143c;
	--Orange: #ff8c00;
	--Gold: #daa520;
	--Yellow: #ffd700;
	--Green: #9acd32;
	--Cyan: #00ced1;
	--Blue: #007bc7;
	--Purple: #800080;
	--Pink: #db7093;
	--Brown: #964b00;
}

// General
$border-radius: 0.5rem;
$body-bg: $lightest;
$enable-shadows: true;
$main-box-shadow: 0 0.125rem 0.25rem rgba($dark, 0.075);

// Fonts
$display-font-sizes: (
	1: 3rem,
	2: 2.5rem,
	3: 2rem,
	4: 1.5rem,
	5: 1.25rem,
	6: 1rem
);
$font-family-base: 'Poppins', sans-serif;
$font-weight-bold: 600;
$headings-font-weight: 600;
$link-hover-color: $primary;

// Form inputs
$box-shadow: $main-box-shadow;
$input-bg: white;
$input-border-width: 0;
$input-box-shadow: $main-box-shadow;
$input-group-addon-bg: $border;
$form-select-box-shadow: $main-box-shadow;

// Buttons
$btn-border-radius: 0.25rem;
$btn-box-shadow: 0;

// Dropdowns
$dropdown-bg: 'white';
$dropdown-border-color: var(--border);
$dropdown-box-shadow: $main-box-shadow;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.5rem;

// Modals
$modal-content-bg: 'white';
